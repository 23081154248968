<template>
  <div class="row feed boxw mb-1">
    <div class="box pb-2">
      <div class="pt-2">
        <nav class="nav nav-pills flex-column flex-sm-row">
          <a @click="gender='male'; init()" :class="{'flex-sm-fill text-uppercase text-sm-center nav-link': 1, 'active': gender === 'male'}">{{$t('male')}}</a>
          <a @click="gender='female'; init()" :class="{'flex-sm-fill text-uppercase text-sm-center nav-link': 1, 'active': gender === 'female'}">{{$t('female')}}</a>
          <a @click="gender='couple'; init()" :class="{'flex-sm-fill text-uppercase text-sm-center nav-link': 1, 'active': gender === 'couple'}">{{$t('couple')}}</a>
          <a @click="gender='other'; init()" :class="{'flex-sm-fill text-uppercase text-sm-center nav-link': 1, 'active': gender === 'other'}">{{$t('other')}}</a>

          <a @click="gender='match'; matchFN();" :class="{'flex-sm-fill text-sm-center nav-link': 1, 'active': gender === 'match'}">{{$t('match')}}</a>
        </nav>
      </div>

      <div v-if="gender === 'match'">
        <div v-if="matchs[0]">
          <masonry-wall :items="matchs" :ssr-columns="3" :column-width="200" :gap="16">
            <template #default="{ dt, index }">
              <div>
                <a>
                  <img style="max-width: 100%" v-lazy="'/users/' + Math.floor(dt.id / 1000) + '/' + dt.username + '/imgp.jpg'">
                </a>
                <button @click="delMatch(dt.match_hash, index)"><i class="fa fa-times"></i></button>
              </div>
            </template>
          </masonry-wall>
        </div>
        <div v-else v-html="$t('sem_resultados')"></div>
      </div>
      <div class="tinder loaded" v-else>
        <div class="tinder--status">
          <i class="fas fa-times"></i>
          <i class="fa fa-fire"></i>
        </div>

        <div class="tinder--cards"><!--v-touch:swipe.left="createButtonListener(false)" v-touch:swipe.right="createButtonListener(true)"-->
          <div class="tinder--card" v-for="(dt, index) in items" :key="index" :style="'z-index: ' + (items.length  - index) + '; transform: scale(' + ((20 - index) / 20) + ') translateY(-' + 30 * index + 'px); opacity: ' + (10 - index) / 10 + ';transition-duration: 1.5s;'">
            <img :src="'/users/' + Math.floor(dt.id / 1000) + '/' + dt.username + '/imgp.jpg'">
            <h3>{{ dt.username }}</h3>
          </div>
        </div>
        <div class="tinder--buttons">
          <button id="nope" @click="createButtonListener(false)"><i class="fas fa-times"></i></button>
          <button id="love" @click="createButtonListener(true)"><i class="fa fa-fire"></i></button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { matchFeed, matchPlay, match, matchDelete } from './../resources/fn'
import { success, error } from '../resources/functions'

export default {
  name: 'tinder',
  data () {
    return {
      items: [],
      matchs: [],
      card: 0,
      gender: 'couple'
    }
  },
  beforeMount () {
    this.init()
  },
  computed: {
    ...mapState({
      user: 'user'
    })
  },
  watch: {
    gender (b, c) {
      this.init()
    }
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_USER'
    ]),
    init () {
      this.SET_LOAD(true)
      this.items = []
      matchFeed(this.axios, this.gender).then((res) => {
        this.items = res.data
        this.card = 0
        this.SET_LOAD()
      }, (res) => {
        error(this, this.$t('tinder.erro_carregar_match'))
        this.SET_LOAD()
      })
    },
    createButtonListener (love) {
      this.SET_LOAD(true)
      console.log(this.items, this.items[this.card].id, this.card)
      matchPlay(this.axios, this.items[this.card].id, this.items[this.card].username, { response: love, match_hash: this.items[this.card].match_hash }).then((res) => {
        document.getElementsByClassName('tinder--card')[this.card].style.transform = 'translate(' + (love ? '' : '-') + (document.body.clientWidth * 1.5) + 'px, -100px) rotate(' + (love ? '-' : '') + '30deg)'
        ++this.card
        if (this.card === 20) {
          console.log('.,.,,.,.')
          this.init()
        }
        if (res.data.success === 'match') {
          success(this, this.$t('tinder.ambos_deram_match'))
        }
        this.SET_LOAD()
      }, (res) => {
        error(this, this.$t('tinder.erro_dar_match'))
        this.SET_LOAD()
      })
    },
    matchFN () {
      this.SET_LOAD(true)
      match(this.axios).then((res) => {
        this.matchs = res.data
        this.SET_LOAD()
      }, (res) => {
        error(this, this.$t('tinder.erro_carregar_meus_matchs'))
        this.SET_LOAD()
      })
    },
    delMatch (matchhash, index) {
      this.SET_LOAD(true)
      matchDelete(this.axios, matchhash).then(() => {
        this.matchs.splice(index, 1)
        this.SET_LOAD()
      }, () => {
        error(this, this.$t('tinder.apagar_match'))
        this.SET_LOAD()
      })
    }
  }
}
</script>
